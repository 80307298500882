export default {
    alerts: {
        index: {
            error: 'Échec de la récupération des comptes',
        },
        store: {
            error: 'Échec de la création du compte',
            success: 'Compte créé avec succès',
        },
        show: {
            error: 'Échec de la récupération du compte',
        },
        update: {
            error: 'Échec de la mise à jour du compte',
            success: 'Compte mis à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression du compte',
            success: 'Compte supprimé avec succès',
        },
        facebook: {
            success: 'Compte lié à Facebook avec succès',
        },
    },
    edit: {
        alertTaskOverlap: 'Avertissement lorsqu\'une tâche se chevauche avec une autre',
        access_all_leads_communications: 'Communiquer avec tous les leads',
        access_all_leads_communications_tool_tip: "Permet à tous les utilisateurs d'entrer en communication avec tous les leads",
        accessories: 'Accessoires',
        accidented_damage_cost: 'Accident $',
        accountClosed: 'Le compte est fermé ou suspendu dans Twilio. Veuillez sauvegarder le compte (CRM) dabord.',
        accountInformation: 'Informations du compte',
        accountName: 'Nom',
        accountPhone: 'Téléphone',
        accountType: 'Type',
        accSaleSpecial: 'Pneus et accessoires',
        activateIvr: 'Activé / désactivé IVR',
        activationDate: "Date d'activation",
        activeDisabledApiUser: 'Les utilisateurs API ne peuvent pas être actifs',
        activeInactive: 'Actif / Inactif',
        activityReport: "Rapport d'activités",
        actualValue: 'Valeur réelle',
        addLogo: 'Cliquez ici pour ajouter votre logo français (Par défaut si pas de logo anglais)',
        addLogoEn: 'Cliquez ici pour ajouter votre logo anglais (Par défaut si pas de logo français)',
        address: 'Adresse',
        admin: 'Administation',
        allowedMileage: 'Km Alloué',
        allTime: 'En tout temps',
        approved: 'Approuvé',
        assignedLead: 'Attribution de lead sans actions',
        assignedLeadDesc: "Activez cette option pour permettre aux conseillers et aux agents de s'attribuer des leads s'ils ne sont pas déja assigné sans avoir généré un appel ou sans avoir envoyé un courriel.",
        audioOverwriteName: "Le nom n'est pas utilisé dans le cas où un audio d'accueil est présent",
        restrictLeadStatusUpdateForRoles: 'Restreindre les statuts de leads pour certains rôles',
        restrictLeadStatusUpdateForRolesTooltip: 'Les rôles sélectionnés ne pourront pas attribuer le statut "perdu" ou "invalide" à un lead.',
        autoAssignAssociate: 'Auto assignation durant association',
        autoAssignAssociateDelay: 'Délai d\'auto-assignation',
        autoAssignAssociateTooltip: 'Assigner le conseiller automatiquement lors de nouvelle association de lead',
        autoAssignAssociateDelayTooltip: 'Permets de préciser le délai requis pour l\'auto-assignation d\'un conseiller au lead.',
        enforcedMfa: 'Authentification multifacteur obligatoire',
        enforcedMfaTooltip: "Rendre l'authentification multifacteur obligatoire pour tous les utilisateurs",
        flagLeadDuplicated: 'Signaler comme Doublon durant association',
        flagLeadDuplicatedTooltip: 'Mettre le statut dupliquer lors d\'une assignation automatique',
        automatchIncomingCall: 'Correspondance automatique des appels entrants',
        automatchIncomingCallTooltip: 'Permet de lier automatiquement les appels entrants au lead avec le numéro de téléphone correspondant. Création d\'un nouveau lead si aucun lead correspondant.',
        automatchMandatoryTooltip: 'Cette option est obligatoire lorsque le système téléphonique RingCentral est sélectionné',
        automations: 'Automatisations',
        automobile: 'Concessionnaire',
        autoRenewal: '@.capitalize:lexicon.portfolio.singular automatique',
        autoRenewalDescription: 'Crée automatiquement un lead de type @:lexicon.portfolio.singular après chaque @:lexicon.delivery.singular',
        balance: 'Solde',
        bBackDescription: 'Renvoi du conseiller au bas de la liste même pour un rendez-vous avec un "B-Back"',
        bBackStayRole: 'B-Back no next',
        bdcAgent: 'Agent BDC',
        bilingual: 'Bilingue',
        blacklistPhones: 'Téléphone non intégré|Téléphones non intégrés',
        blacklistPhonesTooltip: 'Ces numéros ne seront pas intégrés dans les leads Phone-up',
        bodyType: 'Carrosserie',
        breakStartEndTime: 'Heure de départ & retour des pauses',
        budget: 'Budget (min./max.)',
        budgetTextField: 'Budget',
        business: 'Affaires',
        businessHours: "Heures d'ouverture",
        businessHoursDivision: 'Heures d\'ouverture par défaut',
        businessHoursDivisionTooltip: "Heures d'ouverture par défaut pour les leads sans division.",
        calendar: 'Options calendrier',
        calendarOptions: 'Calendrier',
        callDate: 'Date d\'appel',
        callSummary: 'Résumé d\'appel',
        callSummaryTooltip: 'Afficher une pop-up après les appels téléphoniques sortants permettant aux utilisateurs de laisser plus de commentaires',
        callSummaryRequired: 'Obligatoire pour tous les utilisateurs',
        callSummaryRequiredTooltip: 'Exiger que tous les utilisateurs remplissent la pop-up de résumé d\'appel et les empêcher de l\'ignorer',
        calledCount: 'Nombre d\'appel',
        callerId: 'Caller ID',
        campaign: 'Campagne',
        city: 'Ville',
        clientNumber: '# de client',
        closing: 'Fermeture',
        color: 'Couleur',
        certified: 'Certifié',
        colorExterior: 'Couleur Extérieure',
        colorInterior: 'Couleur Intérieure',
        comment: 'Commentaires',
        commercialColumn: 'Colonne directeur commercial',
        commercialDirector: 'Directeur Commercial',
        commercialInTurn: 'Directeurs commerciaux',
        commercialInTurnDescription: 'Ajoute un tour de rôle pour les directeurs commerciaux',
        communicationPreference: 'Préférence',
        communicationPreferenceTooltip: 'Préférence de communication',
        condition: 'Condition',
        confidentialProfit: 'Profits confidentiels',
        confidentialProfitTooltip: 'Cache la section profits pour les agents et les directeurs BDC. Pour les conseillers, affiche seulement ses propres profits.',
        confirmationAppt: 'Confirmation RDV',
        confirmationApptTooltip: 'Propose la confirmation de RDV par SMS pour chaque rendez-vous ajouté.',
        continueWithFacebookButton: 'Continuer avec Facebook',
        contractEnd: 'Fin de contrat',
        country: 'Pays',
        copyModifs: 'Copier les modifications dans les comptes enfants',
        checkAvailabilityRoundRobin: 'Vérifier la disponibilité pour Round Robin',
        checkAvailabilityRoundRobinTooltip: 'Vérifier la disponibilité dans l\'horaire des conseillers avant de les assigner',
        createChildModal: 'Créer un utilisateur enfant dans le nouveau compte enfant',
        createChildModalDescription: 'Sélectionner les utilisateurs pour lesquels vous voulez créer un utilisateur enfant',
        createdMethod: 'Méthode de création',
        credit: 'Crédit',
        crmMax: 'CRM $',
        csi: 'CSI',
        csiDelaysOption: 'Délais de rappel',
        csiDelaysOptionTooltip: 'Délais pour la création automatique des tâches CSI',
        csiTooltip: 'Créer automatiquement des tâches "CSI" suite à une @:lexicon.delivery.singular . Une tâche sera créée pour chaque délai sélectionné.',
        csiUsed: 'Occasion',
        csiNew: 'Neuf',
        currentVehicles: 'Véhicules actuels',
        customInput: 'Champs personnalisés',
        customFieldDisabledBadge: 'Utilisé pour le badge du TDV',
        day: 'Jour',
        dealer: 'Concessionnaire',
        dealerPhoneMustBeBlacklisted: 'Impossible de modifier le téléphone du concessionnaire',
        defaultDeliveryman: 'Livreur par défaut',
        defaultDeliverymanTooltip: 'Sélectionnez un utilisateur qui sera présélectionné en tant que livreur dans les @:lexicon.delivery.plural',
        deleteAccount: 'Supprimer le compte',
        deliveryTimeframe: 'Temps de @:lexicon.delivery.singular par défaut',
        deliveryGuestAdvisor: 'Conseiller comme invité',
        deliveryGuestAdvisorTooltip: 'Ajouter automatiquement le conseiller comme invité aux tâches de @:lexicon.delivery.plural',
        deliveryGuestGroup: 'Groupe d\'invités de @:lexicon.delivery.singular',
        deliveryGuestGroupTooltip: 'Ajouter automatiquement le groupe d\'invités aux tâches de @:lexicon.delivery.plural',
        defaultMonthlyMileage: 'Kilométrage mensuel par défaut',
        defaultMonthlyMileageTooltip: 'L\'estimation du kilométrage utilisera cette valeur si aucune donnée n\'est présente.',
        departmentMoney: 'Dashboard $',
        desking: 'Desking',
        deskManagerInTurn: 'Desk managers',
        deskManagerInTurnDescription: 'Ajoute un tour de rôle pour les desk managers',
        directionEmailPoll: 'Sondage direction',
        directionPoll: 'Sondage direction',
        directorAlerts: 'Directeurs',
        directorManagement: 'Gestion directeur uniquement',
        directorManagementDescription: "Empêche les conseillers d'utiliser les statuts (en rencontre, pause et @:lexicon.delivery.singular)",
        disabled: 'Désactivé',
        disabledManualDuplicate: 'Doublon manuel désactivé',
        disabledManualDuplicateTooltip: "Empêcher la création d'un lead manuel lorsqu'un lead avec la même adresse courriel est trouvé",
        disconnectFacebookButton: 'Déconnecter Facebook',
        displayApprovedSources: 'Afficher les sources approuvées',
        displayApprovedSourcesTooltip: 'Affiche la source approuvée lorsqu\'elle existe au lieu de la source originale',
        division: 'Division',
        divisionRequired: 'Division requise',
        divisionGroupedTotalColumn: '@.capitalize:lexicon.sale.singular par division',
        divisionGroupedTotalColumnTooltip: 'Ajoute une colonne dans votre tableau des @:lexicon.sale.plural qui permet de voir les résultats cumulé par division.',
        dmsExchange: 'DMS',
        dmsExchangeExport: 'Intégrations sortantes',
        dmsExchangeImport: 'Intégrations entrantes',
        dnsValidMessage: 'Vos DNS transactionnel et marketing sont actifs',
        dnsTransactionalMessage: "Votre DNS transactionnel n'est pas actif",
        dnsMarketingMessage: "Votre DNS marketing n'est pas actif",
        documentation: 'Documentation',
        drivingWheels: 'Roues motrices',
        duplicates: 'Doublons',
        email: 'Courriel',
        emailSubdomain: 'Sous-domaine de courriel',
        employeeAlerts: 'Employés',
        endWarrantyDate: 'Fin de garantie',
        endWarrantyMileage: 'Fin de garantie KM',
        endExtendedWarrantyDate: 'Fin de garantie pro.',
        endExtendedWarrantyDateTooltip: 'Fin de garantie prolongée',
        endExtendedWarrantyMileage: 'Fin de garantie pro. KM',
        endExtendedWarrantyMileageTooltip: 'Fin de garantie prolongée KM',
        engine: 'Moteur',
        equity_alert: 'Équité',
        event: 'Événement',
        exchangeVehicleTotal: 'Total véh. ech.',
        excluded: 'Exclus',
        extendedWarranty: 'Garantie prolongée',
        facebookMessenger: 'Facebook Messenger',
        fAndI: 'F&I',
        fields: 'Champs fiche client',
        fill: 'Compléter',
        fillRuleTooltip: 'La fonction "Compléter" ajoute les informations dans les champs vides du CRM uniquement, sans affecter les autres champs.',
        fiProfits: 'Profits F&i',
        forthcoming: 'À venir...',
        fiscalYear: 'Début d\'année fiscal',
        fiscalYearTooltip: 'Date de début de votre année fiscal utilisé pour la sélection "@:datepicker.fiscalYearToDate".',
        frequency: 'Fréquence',
        from: 'De',
        fuel: 'Carburant',
        flowDefaultDivision: 'Division par défaut',
        flowDefaultDivisionTooltip: 'Lead sans division utilisera cette division pour choisir la procédure',
        general: 'Général',
        generalInfo: 'Info. générales',
        generalOptions: 'Générales',
        globalCalendar: 'Calendrier global',
        guestAction: 'Autoriser action aux invités',
        guestActionTooltip: "Autorise à un utilisateur qui n'est pas assigné à un lead de faire une action s'il est invité dans un des événements du lead",
        gunMiss: 'Gun miss',
        gunMissDescription: "Calcul lorsqu'un conseiller est non disponible, alors qu'il est le prochain à répondre.",
        homepage: 'Page d\'accueil',
        hiddenNumber: 'Numéro caché',
        imapEmail: 'Tous les comptes IMAP de ce concessionnaire seront désactivés si vous décochez cette option.',
        exclusions: 'Exclusions',
        imapExcludedDomains: 'Domaine exclu du IMAP|Domaines exclus du IMAP',
        imapExcludedDomainsTooltip: 'Liste des domaines qui seront ignorés dans le IMAP',
        importRule: "Règle d'import (DMS)",
        importRuleTooltip: 'La fonction "Remplacer" remplace les données du CRM par celles disponibles provenant de l’import pour les champs en concurrence, sans affecter les autres champs. La fonction "Compléter" ajoute les informations dans les champs vides du CRM uniquement..',
        in: 'Réception',
        inactiveWillDeletePhonesText: "La mise à inactif d'un compte entraine la suppression de ses numéros",
        inactiveWillDeletePhonesTitle: 'Êtes-vous certain?',
        infinitePriorityWarning: 'En mode infini, seuls les utilisateurs dans la première priorité auront accès aux leads.',
        initialCash: 'Comptant initial',
        inspected: 'Inspecté',
        institution: 'Institution',
        intention: 'Intention',
        inTurn: 'Tour de rôle',
        inTurnBeBack: 'Rencontre be-back',
        inTurnBeBackDescription: "Permets aux conseillers de rencontrer un client avec un be-back (si cocher dans l'heure avant la rencontre) sans perdre son tour",
        inTurnOptions: 'Tour de rôle',
        ivrAudioInvalidEn: 'Audio choix invalide anglais',
        ivrAudioInvalidFr: 'Audio choix invalide français',
        ivrAudioMenuEn: 'Audio menu anglais',
        ivrAudioMenuFr: 'Audio menu français',
        ivrAudioTransferEn: 'Audio transfert anglais',
        ivrAudioTransferFr: 'Audio transfert français',
        ivrAudioWelcome: 'Audio accueil',
        ivrLocale: 'Langue',
        ivrName: 'IVR Nom',
        ivrSection: 'IVR',
        ivrServicePhone: 'Tel. service',
        ivrWaitingSound: "Son d'attente",
        leadForm: 'Formulaire',
        leadsOtherDivision: 'Afficher les leads des autres divisions',
        leadsOtherDivisionDesc: 'Afficher les leads des autres divisions dans les tableaux de bord',
        leadType: 'Type de client',
        leadXpress: 'Lead Web',
        leadXpressOptions: 'Lead Web',
        leadxpressPriority: 'Priorités Lead Web',
        leadXpressRoundRobin: 'Procédure Lead Web utilise Round Robin',
        leadXpressSchedule: 'Délai calculé avec l\'horaire',
        leadXpressScheduleTooltip: 'Le délai entre les priorités et les rappels seront calculés avec l’horaire de la concession',
        length: 'Longueur (min./max.)',
        licensePlate: 'Numéro de plaque',
        limitedAudioAccess: 'Accès audio limité',
        limitedAudioAccessDesc: "Cette fonction limite l'accès du rôle conseiller à leurs audios uniquement.",
        link: 'Lien',
        locale: 'Langue',
        logo: 'Logo français',
        logoEn: 'Logo anglais',
        lostDirOnly: 'Statut perdu dir. seulement',
        loyalty: 'Loyauté',
        makeModelRequiredForSale: 'Marque et modèle requis pour @:lexicon.sale.singular',
        makeModelRequiredForSaleTooltip: 'Un lead ne peut être mis à @:lexicon.sold.singular sans marque et modèle',
        makeModelStockRequiredForSale: 'Marque, modèle et stock requis pour @:lexicon.sale.singular',
        makeModelStockRequiredForSaleTooltip: 'Un lead ne peut être mis à @:lexicon.sold.singular sans marque, modèle et information de stock',
        makeModelText: 'Champ texte marque & modèle',
        mandatoryCoordinate: 'Courriel ou tél. obligatoire',
        mandatoryCoordinateTooltip: "Oblige l'utilisateur à entrer soit le courriel ou le téléphone lors de la création manuelle de lead",
        manuallyStatus: 'Modification manuelle du résultat',
        manuallyStatusDesc: "Permet aux conseillers de modifier manuellement le résultat d'un lead même si celui-ci a des communications sortantes",
        markSoldLeadAsReached: 'Marquer les leads @:lexicon.sold.plural comme rejoint',
        markSoldLeadAsReachedTooltip: 'Une date de @:lexicon.sale.singular va automatiquement changer le résultat des leads à "Rejoint".',
        maximumBreakDuration: 'Durée maximum des pauses',
        mergeRule: 'Règle de fusion',
        mergeRuleTooltip: 'La fonction "Remplacer" remplace les données du CRM par celles disponibles provenant de l’import pour les champs en concurrence, sans affecter les autres champs. La fonction "Compléter" ajoute les informations dans les champs vides du CRM uniquement.',
        met: 'Rencontré',
        mileage: 'Odomètre',
        mileageExcess: 'Odo. excédentaire estimé',
        mmTextFields: 'Champs de texte M&M',
        modality: 'Modalité',
        modulesOptions: 'Modules et options',
        music: 'Musique',
        needActiveAccount: 'Le compte doit être actif pour ajouter des numéros',
        needIvrNumber: 'Un numéro de téléphone entrant ayant "IVR" coché est requis pour l\'utilisation du IVR',
        new: 'Neuf',
        never: 'Jamais',
        nextStep: 'Prochaine étape automatique',
        nioText: 'NioText',
        nioTextAppointment: 'NioText Rendez-vous',
        nioTextCampaign: 'NioText Campagne',
        objectives: 'Gestion des objectifs',
        offerNumber: '# Offre',
        onlyModelText: 'Champ texte modèle seulement',
        onlyOnUpdate: 'Non disponible lors de la création',
        open: 'Ouvert?',
        opening: 'Ouverture',
        optionsMoney: 'Options',
        orderNumber: '# Commande',
        out: 'Envoi',
        overwrite: 'Remplacer',
        overwriteRuleTooltip: 'La fonction "Remplacer" remplace les données du CRM par celles disponibles provenant de l’import pour les champs en concurrence, sans affecter les autres champs.',
        pauseRoundRobinWhenClosed: 'Pause du Round Robin à la fermeture',
        pauseRoundRobinWhenClosedTooltip: 'À la fermeture de la concession, Round Robin se mettra en pause jusqu\'à la réouverture',
        payment: 'Paiement',
        paymentWithTax: 'Paiement avec taxes',
        performance: 'Performance',
        phoneProvider: 'Fournisseur de téléphonie',
        phoneSystem: 'Système téléphonique',
        phoneUp: 'Phone-up',
        plannedMeeting: 'Rendez-vous planifié',
        plannedMeetingDescription: 'Permets aux conseillers de rencontrer un client avec un rendez-vous planifié (dans les 4 heures suivantes ou précédentes) sans perdre son tour',
        powerSport: 'Power sport',
        preferences: 'Préférences',
        prepaid: 'Prépayé',
        preparation: 'Préparation',
        prepared: 'Préparé',
        pressToTalkTooltip: 'Cette option oblige l\'utilisateur à confirmer la prise en charge de l\'appel Phone-up en appuyant sur le "5" ',
        price: 'Prix',
        process: 'Processus',
        profit: 'Profit',
        programmableCalendar: 'Calendrier prog.',
        source: 'Source',
        providerNumber: 'Numéro',
        province: {
            CA: 'Province',
            US: 'État',
        },
        purchaseDate: "Date d'achat",
        qualification: 'Qualification',
        raiseStatus: 'Afficher le statut relance',
        rate: 'Taux',
        rating: 'Évaluation',
        reactColumn: 'Colonnes réact.',
        recall: 'Rappel',
        recordDate: 'Engr. le',
        recordDateLong: 'Date enregistré',
        recordDateTooltip: "Activer cette option pour ajouter un champs qui permet d'indiqué une date d'enregistrement.",
        recordingMessage: "Message d'enregistrement",
        recordingMessageInbound: "Message d'enregistrement entrant",
        recordingMessageOutbound: "Message d'enregistrement sortant",
        recordingMessageRequiredTooltip: "Message d'enregistrement requis",
        recordingMessageTooltip: 'Message qui avertit le client que l’appel pourrait être enregistré',
        recreative: 'Power Sport',
        recreativeSpecial: 'Spécial récréatif',
        refinanced: 'Racheté',
        refinancedTooltip: '',
        renew: '@.capitalize:lexicon.portfolio.singular',
        renewalRequired: '@.capitalize:lexicon.portfolio.singular doit être actif',
        restrictedNotifications: 'Notifications SMS désactivées',
        restrictedNotificationsTooltip: 'Empêche les utilisateurs d\'activer les notifications SMS',
        equityRequired: 'Équité doit être actif',
        renewed: '@.capitalize:lexicon.renewed.singular | @.capitalize:lexicon.renewed.plural',
        repetition: 'Répétition',
        resetEveryNight: 'Réinitialiser à chaque nuit',
        residual: 'Résiduel',
        ringing: 'Sonnerie',
        roundRobinTooltip: 'Auto-Assignation & Distribution équitable ne doit pas être actif',
        sale: '@.capitalize:lexicon.sale.singular',
        saleByPhone: '@.capitalize:lexicon.sale.plural par tél.',
        saleTableBadge: 'Badge',
        saleTableBadgeTooltip: 'Permets d\'afficher un badge sur le Tableau des @:lexicon.sale.plural lorsqu\'un champ personnalisé est défini.',
        salesChart: 'Tableau des @:lexicon.sale.plural',
        salesChartOptions: 'Tableau des @:lexicon.sale.plural',
        saved: 'Compte sauvegardé avec succès',
        scanXpress: 'Scan Xpress',
        schedule: 'Horaire',
        screen: 'Écran',
        secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
        secondaryAdvisorTooltip: "Permet d'assigner un @:lexicon.secondaryAdvisor.singular à un lead",
        sector: 'Secteur',
        securityDeposit: 'Dépôt sécurité',
        segment: 'Segment',
        selectFabebookPages: 'Quelle page voulez-vous connecter à votre compte',
        service: 'Service',
        serviceDisabled: 'La division service sera retiré a tous les utilisateurs du compte et désactivera les utilisateurs de service (incluant les aviseurs et les agents de service)',
        servicePrices: 'Prix des services',
        simultaneousEmployeeBreaks: 'Employés en pause simultanée',
        sleeping: 'Couchage',
        sms: 'SMS',
        soldBy: '@.capitalize:lexicon.sold.singular par',
        soldDate: 'Date de @:lexicon.sale.singular',
        sourceMandatory: 'Source obligatoire',
        sourceMandatoryTooltip: 'Source obligatoire pour la création manuelle de lead',
        status: 'État',
        stock: '# Stock',
        stockDisabledStockRequiredForSale: 'Cette option est requise lorsque l\'option "Marque, modèle et stock requis pour @:lexicon.sale.singular" est activée',
        stockRequiredForSale: 'Marque, modèle et stock requis pour @:lexicon.sale.singular',
        stockRequiredForSaleTooltip: 'Un lead ne peut être mis à @:lexicon.sold.singular sans marque, modèle et information de stock',
        stopNextStep: 'Arrêter la prochaine étape, si',
        stopNextStepTooltip: 'Arrête la fonctionnalité de prochaine étape si le lead à pour status un de ceux sélectionnés.',
        storage: 'Entreposage',
        suffix: 'Suffixe',
        syncChanges: 'Synchronisation des modifications',
        takeOverDirector: 'T.O. Dir. seulement',
        takeOverDirectorTooltip: "Limite l'utilisation de la case T.O. aux rôles directeurs",
        team: 'Équipe | Équipes',
        term: 'Terme',
        timeLimitToAnswer: 'Délai pour contacter le lead',
        tire_type: 'Type Pneus',
        tireAccessoriesColumn: 'Colonne pneus & accessoires',
        tires: 'Pneus @:lexicon.sold.singular',
        tradeNotes: 'Notes',
        tradeReport: "Rapport d'achat",
        tradeType: 'Achat',
        transmission: 'Transmission',
        unassignUnansweredLead: 'Retirer l\'utilisateur d\'un lead non répondu',
        unassignUnansweredLeadTooltip: 'Retirer automatiquement un utilisateur d\'un lead qui n\'a pas été répondu après une période de temps déterminée',
        unrestrictedAssignment: 'Assignation complémentaire',
        unrestrictedAssignmentTooltip: "Permet d'assigner un agent BDC dans la fiche d'un autre conseiller",
        untreatedLeadAlert: 'Alerte lead en attente',
        untreatedLeadTooltip: 'Une alerte sera envoyée seulement si personne n\'est dans la procédure de la division du lead',
        used: 'Occasion',
        user: 'Utilisateur',
        updateChildAccount: 'Copier les modifications faites dans le compte parent vers les comptes enfants',
        vAutoFallback: 'Conseiller vAuto par défaut',
        vAutoFallbackPlaceHolder: 'Utilisateur vAuto',
        vAutoFallbackTooltip: "Courriel de l'utilisateur vAuto qui pourra voir les soumissions lorsque le Lead n'a pas de conseiller.",
        vehicleCategory: 'Catégorie de véhicule',
        vehicleCategoryRV: 'Catégorie VR',
        vehicleHereOn: 'Veh. ici le',
        verifiedSale: '@.capitalize:lexicon.sale.singular vérifiée',
        allowCommunicationsWhenverifiedSale: 'Permet les communications',
        verifiedSaleTooltip: "Permet aux directeurs d'indiquer @:lexicon.sale.prefix comme étant vérifiée",
        allowCommunicationsWhenverifiedSaleTooltip: 'Autoriser les communications lorsque la vente vérifiée est activée',
        videoconference: 'Vidéoconférence',
        autoRecordedDate: 'Enrg. Le automatique',
        autoRecordedDateTooltip: 'Appliquer la date de @:lexicon.delivery.singular à la date d\'enregistrement.',
        vehicleState: 'État du véhicule',
        vin: 'NIV',
        vinDecoder: 'Scanneur de NIV',
        vinManualDecoder: 'Décodeur de NIV',
        waitingSale: '@.capitalize:lexicon.sale.singular en attente',
        waitingSaleTooltip: 'Affiche les @:lexicon.sale.plural non @:lexicon.delivered.femininePlural et sans dates de @:lexicon.delivery.singular prévues, en dehors des dates sélectionnées.',
        walkAround: 'Walk-around',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        wantedMoney: '$ Souhaité',
        wantedVehicles: 'Véhicules recherchés',
        wantedVehicle: 'Vehicule recherché',
        tradeInVehicle: 'Véhicule d\'échange',
        wantedVehicleTotal: 'Total véh. rech.',
        warranty: 'Garantie',
        warrantyTypeDisableToolTip: 'Il n\'y a pas de garantie disponible pour ce vehicle',
        webBoost: 'WebBoost',
        webOrder: 'Commande Web',
        weight: 'Poids',
        worth: 'Valeur',
        year: 'Année (min./max.)',

        equityAlert: 'Options @:lexicon.portfolio.singular',
        renewalOptions: {
            title: '@.capitalize:lexicon.portfolio.singular',
            tooltip: 'Notification envoyée tous les lundis à l\'ouverture du concessionnaire',
            tooltipRequiredFields: 'Les champs suivants sont requis pour l\'utilisation de l\'alerte: ',
            toolTipHasNotAccessEquity: 'Contacter le support pour modifier le module équité.',
            tooltipModelsDisabled: 'Choisir seulement une marque afin de spécifier des modèles',
            tooltipAddRenewalSetting: 'Ajouter un paramètre de condition',
            tooltipAddRenewalSettingDisabled: 'Vous devez activier l\'option équité dans le compte pour utiliser cette option.',
            tooltipSaveButtonDisabled: 'Le paramètre de condition nécessite au moins une marque ou une année pour être valide',
            tooltipExcessMileageDelay: 'Temps minimum d\'ici la fin de la garantie pour générer une alerte',
            tooltipExcessMileagePercentage: 'Pourcentage minimum du kilométrage excédentaire estimé pour générer une alerte',
            condition: 'Condition',
            equityBase: 'Condition par défaut du véhicule',
            equityBaseCondition: 'Condition',
            equityBaseDescription: 'Cette condition sera celle utlisée si la condition du véhicule actuel n\'est pas définie',
            equityBaseOptions: 'Conditions d\'évaluation du véhicule',
            endLocation: 'Fin de location',
            endLocationDelay: 'Délais de notification',
            endWarranty: 'Fin de garantie',
            endWarrantyDelay: 'Délais de notification',
            equity: {
                xclean: 'Extra Clean',
                extra_clean: 'Extra Clean',
                clean: 'Clean',
                average: 'Average',
                rough: 'Rough',
            },
            excessMileage: 'Kilométrage excédentaire estimé',
            excessMileageDelay: 'Délai minimum',
            excessMileagePercentage: 'Dépassement (%)',
            makes: 'Marques',
            models: 'Modèles',
            missingFields:
                'Les champs suivant sont nécessaires à l\'utilisation complète du produit, voulez-vous les activer?<br><b>{0}</b>',
            operators: {
                equals: 'Est',
                lessThan: 'Avant',
                moreThan: 'Après',
            },
            year: 'Année',
        },
        customFields: {
            addButton: 'Champ personnalisé',
            clientCardSection: 'Section',
            default: 'Par défaut',
            fieldData: 'Données',
            fieldTitle: 'Titre',
            fieldType: 'Type',
            fieldTypeDisabled: 'Impossible de modifier le type sur un champ utilisé dans des leads.',
            modalTitle: 'Champ personnalisé',
            typeArray: 'Choix multiple',
            typeCurrency: 'Monétaire',
            typeBoolean: 'Case à cocher',
            typeDatetime: 'Date',
            typeString: 'Champ de texte',
            typeTextArea: 'Zone de texte',
            typeUrl: 'URL',
            textAreaOption: 'Affichage',
            textAreaLine: 'Une ligne (...)',
            textAreaBox: 'Boite de texte',

            deleteModal: {
                confirm: 'Oui, supprimer!',
                description: 'Supprimer ce champ supprimera également les données personnalisées de <span class="font-semibold">{0} leads</span>.',
                step2Description: 'Cette action est irréversible et supprimera toutes les données de ce champ dans chacun des leads.',
            },
            errors: {
                duplicateOption: 'Valeur déjà existante.',
                duplicateTitle: 'Titre déjà existant.',
                maxOptions: 'Le nombre maximal de {0} options est atteint.',
            },
            retroactiveModal: {
                title: 'Valeur par défaut rétroactive',
                confirm: 'Oui',
                description: 'Voulez-vous remplir ce champ avec la valeur par défaut pour tous les leads existants?<br>Cette action est <span class="font-semibold">irréversible</span> et prendra plusieurs minutes.',
            },
            unsavedModal: {
                confirm: 'Oui, sauvegarder!',
                description: 'Il y a une option non sauvegardée. Êtes-vous sûr de vouloir enregistrer ce champ personnalisé sans cette option?',
            },
        },
        disabledTooptips: {
            mandatoryOptions: 'Certaines options sont obligatoires',
            onlyAdmins: 'Seuls les admins peuvent modifier cette option',
            saleTableRequiresIt: 'Le Tableau des @:lexicon.sale.plural nécessite cette option',
            tradeReportRequires: "L'option Rapport d'achat doit être activée.",
            webCrmRequired: 'Les CRM Lead Web et WebBoost sont requis',
            webOrderRequiresIt: 'Le dashboard Commande Web nécessite cette option',
        },
        guestGroup: {
            add: "Ajouter un nouveau groupe d'utilisateurs",
            cannotAdd: "Il n'y a pas d'utilisateurs actifs dans le compte pour créer un nouveau groupe d'utilisateurs",
            deleteButton: 'Oui, supprimer le groupe',
            deleteConfirmation: "Ce groupe d'utilisateur sera supprimer définitivement.",
            name: 'Nom',
            title: "Groupe d'invités",
        },
        infos: {
            childAccounts: 'Comptes enfants',
            parentAccount: 'Compte parent',
        },
        processFields: {
            approved: 'Approuvé',
            available: 'Véh. ici le',
            csi: 'CSI',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            delivered: '@.capitalize:lexicon.delivered.singular',
            delivery: '@.capitalize:lexicon.deliveryDate.singular',
            discounted: 'Escompté',
            inspected: 'Inspecté',
            leadBank: 'Institution',
            paperwork: 'Dossier',
            prepared: 'Préparé',
            prepared_work_order: 'P.W.O.',
            progressState: 'Approbation',
            gas: 'Ravitaillé',
            recorded: 'Enrg. le',
            waitingSale: '@.capitalize:lexicon.sale.singular en att.',
        },
        provinces: {
            AB: 'Alberta',
            BC: 'Colombie-Britannique',
            MB: 'Manitoba',
            NB: 'Nouveau Brunswick',
            NL: 'Terre-Neuve',
            NS: 'Nouvelle-Écosse',
            NT: 'Territoires du Nord-Ouest',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Île-du-Prince-Édouard',
            QC: 'Québec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
        recordingMessageCustomPhoneProviderTooltip: 'Cette option requiert d\'avoir Twilio comme système téléphonique principal',
        needTwilioNumber: 'Cette option requiert d\'avoir Twilio comme système téléphonique principal',
    },
    global: {
        goToUsers: 'Accéder à la liste des utilisateurs',
    },
};
