<template>
    <div class="min-h-40 max-w-full mx-auto relative">
        <header class="flex items-center justify-center relative w-full mx-auto" :class="{'xl:w-full': video.status !== 'processed'}">
            <div class="flex items-center text-gray-700 mb-4" v-if="['readyToRecord', 'recordingStopped', 'processed'].includes(video.status)">
                <h3 class="text-xl m-0 p-0 | lg:text-2xl" v-text="$t('communications.video.title')" />
                <div class="flex items-center ml-2 text-gray-700 font-normal tracking-wide px-2 space-x-2 bg-gray-200 rounded">
                    <div v-text="'BETA'" />
                    <icon class="text-gray-600" name="regular/lab-flask-experiment" />
                </div>
            </div>
            <button class="flex items-center absolute right-0 text-red-500 mb-4 pr-3 | hover:text-red-700 | transition duration-150" @click="deleteVideo" v-if="video.status === 'processed'">
                <icon class="mr-2" name="regular/trash-1" />
                <span v-text="$t('communications.video.deleteVideo')" />
            </button>
            <activix-spinner :size="45" class="absolute my-96 -mb-96 z-50" v-if="clickStopRecording && video.status === 'readyToRecord'" />
            <div class="absolute text-xl px-2 my-22 py-3 -mb-36 z-50 bg-white w-full text-center rounded-lg | lg:w-1/2" v-text="$t('communications.video.statusMessage.chooseThumbnail')" v-if="video.status == 'recordingStopped'" />
        </header>

        <div class="flex items-center justify-center mb-2 | lg:mb-0" :class="{'min-h-48': video.status !== 'processed'}" v-show="video.status !== 'processed'">
            <section>
                <div class="flex items-center" v-if="showTitle">
                    <span class="text-gray-700 text-xl m-0 p-0 | lg:text-2xl" v-text="$t('communications.video.title')" />
                    <span class="ml-2 text-gray-700 font-normal tracking-wide px-2 space-x-2 bg-gray-200 rounded">
                        <span>BETA</span>
                        <icon class="text-gray-600" name="regular/lab-flask-experiment" />
                    </span>
                </div>
                <div class="mb-5 mt-2 text-gray-700 w-96" v-text="$t('communications.video.description')" v-if="showTitle" />

                <!-- home -->
                <div class="flex flex-col relative" :class="[clickRecord || ['readyToRecord', 'recordingStopped'].includes(video.status) ? 'w-full' : 'w-96', { 'h-0 opacity-0': ['uploading', 'uploaded', 'processed'].includes(video.status) }]">
                    <div class="overflow-hidden pb-2" :class="recorderClass" ref="ziggeoRecorder" />
                    <activix-spinner
                        :size="45"
                        class="w-full absolute mx-auto text-center mt-16"
                        v-if="clickRecord"
                    />
                    <button class="flex items-center absolute bg-white left-0 top-0 ml-0 mb-4 -mt-10 | lg:-mt-11 | hover:text-gray-700 | transition duration-150" @click="resetVideo" v-if="['readyToRecord', 'recordingStopped'].includes(video.status)">
                        <icon class="mr-2 flip-x" name="regular/arrow-right-1" />
                        <span v-text="$t('communications.video.resetVideo')" />
                    </button>
                </div>

                <!-- uploading -->
                <div class="flex flex-col w-96 mb-3" v-if="['uploading', 'uploaded'].includes(video.status)">
                    <div class="flex items-center justify-between">
                        <template v-if="video.status === 'uploading'">
                            <div class="mb-1 text-gray-600 uppercase tracking-wider" v-text="$t('communications.video.statusMessage.uploading')" />
                            <div class="text-gray-600" v-text="progress" />
                        </template>
                        <template v-if="video.status === 'uploaded'">
                            <div class="mb-1 text-gray-600 uppercase tracking-wider" v-text="$t('communications.video.statusMessage.uploaded')" />
                            <activix-spinner class="mb-1" :size="15" :line-size="2" />
                        </template>
                    </div>
                    <div class="flex items-center flex-col justify-center">
                        <div class="rounded-md bg-gray-300 h-2 w-full | lg:10/12">
                            <div class="rounded-md h-2 bg-blue-500" :style="{ width: progress }" />
                        </div>
                    </div>
                </div>
            </section>

            <div class="pointer-events-none hidden ml-8 | lg:block" v-if="showRecordingButtons || ['uploading', 'uploaded'].includes(video.status)">
                <files-camera />
            </div>
        </div>
        <div class="relative h-0 w-full | lg:10/12 bg-gray-800 rounded-md" style="padding-bottom: 56.25%" v-if="video.status === 'processed'"> <!-- 16:9 -->
            <video controls class="absolute top-0 left-0 w-full h-full rounded-lg" ref="video">
                <source :src="videoFile" type="video/mp4" />
            </video>
        </div>
    </div>
</template>

<script>
    import FilesCamera from '@/assets/svg/illustrations/files-camera.svg';

    export default {
        components: {
            FilesCamera,
        },

        props: {
            ziggeoId: {
                type: String,
            },
            processed: {
                type: Boolean,
                default: false,
            },
            user: {
                type: Object,
            },
            leadId: {
                type: Number,
            },
        },

        data() {
            return {
                clickRecord: false,
                clickStopRecording: false,
            };
        },

        computed: {
            recorderClass() {
                if (this.clickRecord) {
                    return 'invisible h-40';
                }

                if (['readyToRecord', 'recordingStopped'].includes(this.video.status)) {
                    return 'video-recorder-visible';
                }

                return '';
            },

            videoId() {
                return this.leadId;
            },

            showTitle() {
                return !['processed', 'readyToRecord', 'recordingStopped'].includes(this.video.status) && !this.clickRecord;
            },

            showRecordingButtons() {
                return this.video.status === '' && !this.clickRecord;
            },

            videoFile() {
                const ziggeoId = this.ziggeoId ? this.ziggeoId : this.video.ziggeoId;
                return this.$ziggeo.getVideoFile(ziggeoId);
            },

            video() {
                return this.$ziggeo.getVideoById(this.videoId) || { status: '' };
            },

            progress() {
                return `${this.video.progress}%`;
            },
        },

        watch: {
            'video.status': {
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }

                    switch (newValue) {
                        case 'readyToRecord':
                            this.clickRecord = false;
                            this.mountRecorderStopClick();
                            this.setRecorderStyle();
                            break;
                        case '':
                            this.clickRecord = false;
                            this.clickStopRecording = false;
                            this.$ziggeo.mountRecorder(this.videoId, this.$refs.ziggeoRecorder);
                            this.mountRecorderClick();
                            break;
                    }
                },
            },

            ziggeoId(newValue, oldValue) {
                if (!oldValue && newValue && !['uploading', 'readyToRecord'].includes(this.video.status)) {
                    this.clickRecord = false;

                    if (this.$refs.ziggeoRecorder.querySelector('.ba-videorecorder-container')) {
                        this.updateVideo({ status: this.processed ? 'processed' : '', ziggeoId: this.ziggeoId });
                    } else {
                        this.createRecorder();
                    }
                }
            },
        },

        methods: {
            setRecorderStyle() {
                const el = document.querySelector('div[data-selector=video-recorder-container]');
                if (!el) {
                    return;
                }

                el.style.width = '100%';
                el.style.height = '100%';
            },

            onClickRecorder() {
                this.$behavior.track('VideoPresenting', { action: 'record' });
                this.clickRecord = true;
            },

            onClickStopRecorder() {
                if (!this.$refs.ziggeoRecorder) {
                    return;
                }

                if (this.$refs.ziggeoRecorder.querySelector('.ba-videorecorder-disabled')) {
                    return;
                }

                this.clickStopRecording = true;
            },

            updateVideo(payload) {
                this.$ziggeo.updateVideo(this.videoId, payload);
            },

            createRecorder() {
                this.$ziggeo.createRecorder(this.videoId, this.$refs.ziggeoRecorder, this.ziggeoId, this.user);
                this.mountRecorderClick();
            },

            async mountRecorderClick() {
                await this.$nextTick();

                if (!this.$refs.ziggeoRecorder) {
                    return;
                }

                const recordButton = this.$refs.ziggeoRecorder.querySelector('.ba-videorecorder-chooser-button-0');

                if (recordButton) {
                    recordButton.addEventListener('click', this.onClickRecorder);
                }
            },

            mountRecorderStopClick() {
                const recordButton = this.$refs.ziggeoRecorder.querySelector('[data-selector="stop-primary-button"]');
                if (recordButton) {
                    recordButton.addEventListener('click', this.onClickStopRecorder);
                }
            },

            resetVideo() {
                this.$ziggeo.resetVideo(this.videoId);
                this.mountRecorderClick();
            },

            async deleteVideo() {
                this.clickRecord = false;
                this.clickStopRecording = false;
                await this.$ziggeo.deleteVideo(this.videoId, this.video.ziggeoId || this.ziggeoId);
                this.mountRecorderClick();
            },

            onClosed() {
                if (['uploaded', 'uploading'].includes(this.video.status)) {
                    this.$behavior.track('VideoPresenting', { action: 'exit-before' });
                    this.$notify.info(this.$t('communications.video.warning'));
                }

                if (this.$refs.video) {
                    this.$refs.video.pause();
                }

                const recordStartButton = this.$refs.ziggeoRecorder?.querySelector('.ba-videorecorder-chooser-button-0');
                const recordStopButton = this.$refs.ziggeoRecorder?.querySelector('[data-selector="stop-primary-button"]');

                if (recordStartButton) {
                    recordStartButton.removeEventListener('click', this.onClickRecorder);
                }
                if (recordStopButton) {
                    recordStopButton.removeEventListener('click', this.onClickStopRecorder);
                }

                if (['', 'readyToRecord', 'recordingStopped'].includes(this.video.status)) {
                    this.deleteVideo();
                }
            },
        },

        async mounted() {
            this.$nextTick(() => {
                this.createRecorder();
            });
        },

        beforeDestroy() {
            this.onClosed();
        },
    };
</script>
