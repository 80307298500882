<template>
    <div>
        <activix-modal
            size="md"
            :opened="opened"
            :closable="isClosable"
            @close="close"
        >
            <template #header>
                <h4 class="modal-title">
                    {{ $t('communications.callSummary.title') }}
                </h4>
                <div class="flex justify-center mt-2" v-if="hasCallDetails">
                    <div class="flex items-center">
                        <activix-tooltip :content="$t('communications.callSummary.details.duration')">
                            <icon name="regular/time-clock-circle" />
                        </activix-tooltip>
                        <span class="w-full ml-2">
                            {{ callDuration }}
                        </span>
                    </div>
                    <div class="flex items-center ml-4">
                        <activix-tooltip :content="$t('communications.callSummary.details.lead')">
                            <icon name="regular/single-neutral" />
                        </activix-tooltip>
                        <span class="w-full ml-2">
                            {{ lead.first_name }} {{ lead.last_name.toUpperCase() }}
                        </span>
                    </div>
                </div>
            </template>

            <template #body>
                <div class="row">
                    <div class="form-group col-md-6 col-sm-9 col-xs-15">
                        <label for="call-result-options">
                            {{ $t('communications.callSummary.result.label') }}
                            <span class="form-star" v-if="empty(result)"> *</span>
                        </label>

                        <activix-select
                            id="call-result-options"
                            identifier="value"
                            :options="resultOptions"
                            :placeholder="$t('multiselect.selectOption')"
                            v-model="result"
                        >
                            <el-option
                                :label="option.label"
                                :value="option.value"
                                :key="option.label"
                                v-for="option in resultOptions"
                            />
                        </activix-select>
                    </div>

                    <div class="form-group col-md-6 col-sm-9 col-xs-15">
                        <label for="call-purpose-options">
                            {{ $t('communications.callSummary.purpose.label') }}
                            <span class="form-star" v-if="empty(purposes) && isPurposeRequired"> *</span>
                        </label>

                        <activix-multiselect
                            id="call-purpose-options"
                            identifier="value"
                            label="label"
                            :multiple="true"
                            :select-all="false"
                            :searchable="false"
                            :close-on-select="false"
                            :options="purposeOptions"
                            :placeholder="purposeNoSelectionLabel"
                            :disabled="!isPurposeRequired"
                            :selected="purposes"
                            @update="updatePurposes"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 mb-0">
                        <label for="call-note">
                            {{ $t('communications.callSummary.note') }}
                        </label>

                        <textarea
                            id="call-note"
                            class="form-control"
                            rows="3"
                            v-model="note"
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex items-center justify-end">
                    <activix-button
                        class="mr-2"
                        :disabled="$wait.is('updating.communication')"
                        @click="close"
                        v-if="isClosable"
                    >
                        {{ $t('general.cancel') }}
                    </activix-button>
                    <activix-tooltip :content="saveDisabledTooltip">
                        <div>
                            <activix-button
                                type="primary"
                                :loading="$wait.is('updating.communication')"
                                :disabled="hasInvalidInput"
                                @click="save"
                            >
                                {{ $t('general.save') }}
                            </activix-button>
                        </div>
                    </activix-tooltip>
                </div>
            </template>
        </activix-modal>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import { useContextStore } from '@/store/modules/context/store.js';

    import Purpose from '@/entities/Purpose.js';
    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import CommunicationStatus from '@/entities/CommunicationStatus.js';
    import CommunicationType from '@/entities/CommunicationType.js';
    import LeadResult from '@/entities/LeadResult.js';
    import Lead from '@/entities/Lead.js';

    export default {
        data() {
            return {
                opened: false,
                lead: null,
                communication: null,
                callDuration: '',
                result: '',
                purposes: [],
                note: '',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isClosable() {
                return !this.contextAccount.call_summary_required;
            },

            hasCallDetails() {
                return this.lead && this.communication;
            },

            resultOptions() {
                const options = [
                    CommunicationStatus.ANSWERED,
                    CommunicationStatus.ATTEMPTED,
                    CommunicationStatus.VOICEMAIL,
                ];

                return options.map(option => ({
                    value: option,
                    label: this.$t(`communications.callSummary.result.options.${option}`),
                }));
            },

            isResultReached() {
                return this.result.toLowerCase().includes('answered');
            },

            isResultVoicemail() {
                return this.result.toLowerCase().includes('voicemail');
            },

            isPurposeRequired() {
                return this.isResultReached || this.isResultVoicemail;
            },

            purposeOptions() {
                return Purpose.selectPurposeOptions().map(purpose => {
                    return {
                        value: purpose.id,
                        label: purpose.label,
                    };
                });
            },

            purposeNoSelectionLabel() {
                return this.isPurposeRequired
                    ? this.$t('multiselect.selectOption')
                    : this.$t('communications.callSummary.purpose.notRequired');
            },

            hasInvalidInput() {
                return empty(this.result) ||
                    (empty(this.purposes) && this.isPurposeRequired);
            },

            cancelDisabledTooltip() {
                return this.isClosable
                    ? ''
                    : this.$t('communications.callSummary.errors.cancelDisabled');
            },

            saveDisabledTooltip() {
                return this.hasInvalidInput
                    ? this.$t('communications.callSummary.errors.invalidInput')
                    : '';
            },
        },

        watch: {
            result() {
                if (!this.isPurposeRequired) {
                    this.purposes = [];
                }
            },
        },

        methods: {
            loadModal(lead, communication) {
                if (!lead || !communication) {
                    this.$notify.error({
                        title: this.$t('error.communicationNotFound'),
                    });

                    this.close();
                }

                this.opened = true;

                this.lead = new Lead(lead);
                this.communication = communication;

                this.callDuration = this.convertDurationTo_HH_MM_SS(this.communication?.duration_call ?? 0);

                this.result = '';
                this.purposes = [];
                this.note = '';
            },

            convertDurationTo_HH_MM_SS(timeInSeconds) {
                const hours = (Math.floor(timeInSeconds / 3600)).toString().padStart(2, '0');
                const minutes = (Math.floor((timeInSeconds % 3600) / 60)).toString().padStart(2, '0');
                const seconds = (Math.floor((timeInSeconds % 60))).toString().padStart(2, '0');

                return `${hours}:${minutes}:${seconds}`; // HH:MM:SS
            },

            updatePurposes(purposes) {
                this.purposes = purposes;
            },

            async save() {
                this.$wait.start('updating.communication');
                try {
                    await this.$axios.put(
                        `/v1/communications/${this.communication.id}`,
                        this.loadPayloadData(),
                    );

                    if (!this.lead.isResultSet()) {
                        // We need to manually override the lead result according to the communication result
                        await this.$axios.put(`/v1/leads/${this.lead.id}`, {
                            result: this.mapCommunicationResultToLeadResult(),
                        });
                    }

                    this.$wait.end('updating.communication');
                    this.close();
                } catch (error) {
                    this.$wait.end('updating.communication');

                    this.$notify.error({
                        title: this.$t('error.generic'),
                    });
                }
            },

            loadPayloadData() {
                return {
                    user_id: this.authUser.id,
                    communication_method_id: CommunicationMethod.PHONE,
                    communication_type_id: CommunicationType.OUTGOING,
                    status: this.result,
                    purposes: this.purposes?.map(p => p.value),
                    description: this.note,
                };
            },

            mapCommunicationResultToLeadResult() {
                return this.result === CommunicationStatus.ANSWERED
                    ? LeadResult.REACHED
                    : LeadResult.ATTEMPTED;
            },

            resetData() {
                this.opened = false;
                this.lead = null;
                this.communication = null;
                this.callDuration = '';
                this.result = '';
                this.purposes = [];
                this.note = '';
            },

            close() {
                this.opened = false;

                // Emit this to display ModalEvent.vue if "Next step" is active for the auth user
                this.$eventBus.$emit('show-next-step', {
                    lead: this.lead,
                    afterCallNextStep: true,
                });

                this.resetData();
                this.$eventBus.$off('show-call-summary', this.resetData);
            },
        },

        created() {
            this.$eventBus.$on('show-call-summary', this.loadModal);
        },

        beforeDestroy() {
            this.$eventBus.$off('show-call-summary', this.loadModal);
        },
    };
</script>
