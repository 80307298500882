export default {
    a: 'a',
    added: 'added',
    addedNumber: 'added a phone number',
    addedEmail: 'added an email',
    addedCustomable: 'added a value to',
    assignationDemand: 'made an assignment request to become ',
    advisor: 'Associate',
    bdc: 'Agent BDC',
    by: 'by',
    case: 'case',
    deleted: 'deleted this lead',
    deletedNumber: 'deleted the number',
    deletedEmail: 'deleted the email',
    aFeminine: 'a',
    email: 'courriel',
    eventLeadCreated: 'created this event lead from',
    exportedToDeskingTool: 'sent the lead to the Desking tool',
    exportedToDms: 'sent the lead to the DMS',
    followingAutomationSettings: 'according to the automation settings',
    for: 'for',
    from: 'from',
    manualAssociation: 'has created this lead from',
    merged: 'has merged the lead',
    new: 'New',
    newFeminine: 'New',
    newVowel: 'New',
    noHistory: 'No history',
    of: 'of the',
    ofFeminine: 'of the',
    phoneNumber: 'phone number',
    renewalCycle: 'created a @:lexicon.portfolio.singular lead associated with this lead',
    the: 'the',
    to: 'to',
    toThisLead: 'to this lead',
    toThisSalesJourney: 'to this sales journey',
    updated: 'updated',
    associated: 'associated this lead to',
    leadExportedToSupplier: 'sent the lead to',
    leadValue: 'Lead',
    leadPhoneValue: '{0} Phone',
    leadEmailValue: '{0} Email',
    commentValue: 'Comment',
    reminderValue: 'Reminder',
    restored: 'restored this lead',
    exchangeVehicleValue: 'Current vehicle',
    vehicleExportedToSupplier: 'sent the vehicle to',
    wantedVehicleValue: 'Wanted vehicle',
    type: 'the type',

    actions: {
        added: 'added',
        forced: 'forced',
        removed: 'removed',
        restored: 'restored',
        sent: 'sent',
        updated: 'updated',
        withdraw: 'withdraw',
    },

    models: {
        comment: 'Comment',
        communication: 'Communication',
        exchangeVehicle: 'Current vehicle',
        lead: 'Lead',
        leadEmail: 'Email',
        leadPhone: 'Phone',
        taskEvent: 'Task / appointment',
        wantedVehicle: 'Wanted vehicle',
        flow: 'Flow',
        flowUser: 'User',
    },

    prefix: {
        a: 'a',
        an: 'an',
        aFeminine: 'a',
        of: 'of the',
        ofFeminine: 'of the',
        this: 'this',
        thisFeminine: 'this',
        to: 'to',
        toFeminine: 'to',
    },

    communication: {
        entityName: 'Communication',
        field: {
            status: 'Result',
            description: 'Description',
            user: 'User',
            url: 'the Link',
            duration_call: 'Duration Call',
        },
        value: {
            answered: 'Reached',
            attempted: 'Attempted',
            calling: 'Calling',
            delivery_failed: 'Delivery failed',
            error: 'Error',
            interrupted: 'Interrupted',
            invalid: 'Invalid',
            pending: 'Pending',
            unanswered: 'Unanswered',
        },
    },

    lead: {
        nextPresentedDate: 'Next revival',
        storage: 'Storage',
        ratingComment: 'Rating comment',
        rating: 'Rating',
        code: 'Code',
        scraperDescription: 'Content of the original email',

        // Personal info
        address1: 'Address',
        address2: 'Address 2',
        birthDate: 'Date of birth',
        city: 'City',
        country: 'Country',
        firstName: 'First name',
        lastName: 'Last name',
        locale: 'Language',
        longTerm: 'Long term',
        postalCode: {
            CA: 'Postal code',
            US: 'ZIP code',
        },
        province: 'Province',
        secondContact: '2nd contact',
        sex: 'Gender',
        civility: 'Civility',
        secondContactCivility: 'Civility second contact',

        // Global info
        bdcUser: 'BDC Agent',
        commercial: 'F&I',
        deliveredBy: '@.capitalize:lexicon.delivered.singular by',
        firstUpdateTime: 'First update time',
        responseTime: 'Response time',
        result: 'Result',
        secondaryUser: '@.capitalize:lexicon.secondaryAdvisor.singular',
        takeOverDirector: 'Turn Over by',
        unsubscribeCall: 'Unsubscribe Call',
        unsubscribeEmail: 'Unsubscribe email',
        unsubscribeSms: 'Unsubscribe SMS',
        user: 'Associate',
        technician: 'Technician',

        // General info
        agent: 'Agent',
        budget: 'Budget',
        callDate: 'Date of the call',
        calledCount: 'Call count',
        campaign: 'Campaign',
        clientNumber: 'Client #',
        comment: 'Comment',
        communicationPreference: 'Communication preference',
        custom10: 'Custom 10',
        custom1: 'Custom 1',
        custom2: 'Custom 2',
        custom3: 'Custom 3',
        custom4: 'Custom 4',
        custom5: 'Custom 5',
        custom6: 'Custom 6',
        custom7: 'Custom 7',
        custom8: 'Custom 8',
        custom9: 'Custom 9',
        division: 'Division',
        event: 'Event',
        form: 'Form',
        importedCampaign: 'Campaign',
        importSource: 'Source',
        keyword: '@:clientCard.keyword',
        leadForm: 'Form',
        leadType: 'Client type',
        lostReason: 'Lost reason',
        navigationHistory: '@:clientCard.navigationHistory',
        source: 'Source',
        referrer: '@:clientCard.referrer',
        searchTerm: '@:clientCard.searchTerm',
        sector: 'Sector',
        segment: 'Segment',
        serviceAdvisor: 'Service advisor',
        serviceAgent: 'Service agent',
        status: 'Status',
        timeFrame: 'Purchase intent period',

        // Performance
        appointment: 'Appointment',
        appointmentDate: 'Appointment date',
        apptCall: 'Phone appt.',
        apptCallDate: 'Phone appt. date',
        beBack: 'B-back',
        beBackDate: 'B-back date',
        billOfSaleCreatedAt: 'Bill of sale creation date',
        billOfSaleSentToCustomerAt: 'Bill of sale sent to customer date',
        billOfSaleSignedByCustomerAt: 'Bill of sale signed by customer date',
        csi: 'CSI',
        csiDate: 'CSI date',
        customerDepositReceivedAt: 'Customer deposit received date',
        customerReceiptSentAt: 'Customer receipt sent date',
        delivered: '@.capitalize:lexicon.delivered.singular',
        deliveredDate: '@.capitalize:lexicon.delivered.singular date',
        discountedDate: 'Funded',
        deposit: 'Deposit',
        doNotMarket: 'Do not market',
        homePresented: 'Other visit',
        homePresentedDate: 'Other visit date',
        lostSource: 'Lost source',
        presented: 'Visit',
        presentedDate: 'Visit date',
        presentedOther: 'Other visit',
        presentedOtherDate: 'Other visit date',
        refinancedAt: 'Refinanced date',
        roadTest: 'Test drive',
        roadTestDate: 'Test drive date',
        homeRoadTestDate: 'Home test drive date',
        sale: '@.capitalize:lexicon.sale.singular',
        saleByPhone: '@.capitalize:lexicon.sale.singular by phone',
        saleDate: '@.capitalize:lexicon.sale.singular date',
        takeOver: 'Turn over',
        takeOverDate: 'Turn over date',
        walkAround: 'Walk around',
        writeUp: 'Write-Up',

        // Process
        approved: 'Approved',
        available: 'Veh. here on',
        availableDate: 'Veh. here on',
        deliverable: '@.capitalize:lexicon.deliverable.singular',
        deliverableDate: 'Veh. @:lexicon.deliverable.singular on',
        delivery: '@.capitalize:lexicon.delivery.singular',
        deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
        gas: 'Fueled',
        inspected: 'Inspected',
        leadBank: 'Institution',
        paperwork: 'Paperwork',
        paperworkDate: 'Paperwork date',
        prepared: 'Prepared',
        preparedWorkOrder: 'P.W.O.',
        progressState: 'State',
        endServiceDate: 'End of service',
        promisedDate: 'Promised time',
        invoiced: 'Invoiced',
        plannedPickUpDate: 'Planned pick up',
        waitingSale: 'Waiting @:lexicon.sale.singular',
        statusUpdatedAt: 'Status updated at',
        importedBank: 'Institution',

        // Commercial
        commercialMeeting: 'Met',
        commercialNoNeed: 'Excluded',
        productTotal: 'F&I total',

        account: 'Account',
        exported: 'Exported',

        apptConfirmation: 'Appointment confirmed',
        apptConfirmationDate: 'Appointment confirmed date',
        apptValidated: 'Appointment validated',
        createdAt: 'Created at',
        deletedAt: 'Deleted at',
        deletedBy: 'Deleted by',
        originalCreatedAt: 'Original created at',
        endContractDate: 'End contract date',
        evaluated: 'Evaluated',
        freezeFinancialData: 'Lock financial data',
        lastPresentedDate: 'Last visit date',
        reachedClient: 'Reached client',
        refinanced: 'Buy Out',
        refinancedDate: 'Buy Out date',
        refinancedType: 'Buy Out type',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        serviceCleaningCar: 'Cleaning',
        serviceIntervalKm: 'KM interval',
        serviceMonthlyKm: 'Monthly KM',
        updatedAt: 'Updated at',
        qualification: 'Qualification',
        lastDialogsStatus: 'Last dialogs status',
        workOrder: 'W.O. #',
        recoveryPlanned: 'Planned recovery',
        repairOrder: 'Repair order',
        repairDate: 'Repair order date',
        openWorkOrderDate: 'W.O. opening',
        recall: 'Recall',
        endWarranty: 'End warranty',
        endLcap: 'End LCAP',
        endLnette: 'End LNETTE',
        nowShow: 'No show',
        loyalty: 'Loyalty',
        nonConsumerInitiated: 'Non-Consumer Initiated',
        prepaid: 'Prepaid',
        business: 'Business',
        businessName: 'Business name',
        odometerLastVisit: 'Last visit KM',
        twentyFourHour: '24 hr',
        subAccount: 'Concierge account',
        workOrderClosureDate: '@:clientCard.workOrderClosureDate',
        workOrderPartialClosureDate: '@:clientCard.workOrderPartialClosureDate',

        customerIntelligenceUrl: 'Customer Intelligence Url',
        customerIntelligenceSummary: 'Customer Intelligence Summary',
    },
    customer: {
        caslConsentConfirmedTypeManual: 'forced manual consent',
        caslConsentTypeManual: 'manual consent',
        caslConsentConfirmedTypeManualRemoved: 'removed manual consent',
        caslConsentConfirmationSentAt: 'sent consent request',
        caslConsentConfirmationConsentRequest: 'consent request',
    },
    leadPhone: {
        blocked: 'Blocked',
        deletedAt: 'Deleted at',
        extension: 'Extension',
        mobile: 'Mobile',
        phone: 'Number',
        validated: 'Validated',
        typeString: 'the phone type',
        type: {
            work: 'work',
            home: 'home',
            cell: 'cell',
        },
    },
    leadEmail: {
        deletedAt: 'Deleted at',
        email: 'Address',
        typeString: 'the email type',
        type: {
            work: 'work',
            home: 'home',
        },
    },
    comment: {
        content: 'Content',
        deletedAt: 'Deleted at',
        lead: 'Lead',
        private: 'private',
        user: 'User',
    },
    reminder: {
        date: 'Date',
    },
    taskEvent: {
        address: 'Address',
        deletedAt: 'Deleted at',
        description: 'Description',
        endAt: 'End',
        noShow: 'No show',
        confirmed: 'Confirmed',
        canceled: 'Canceled',
        location: 'Location',
        priority: 'Priority',
        startAt: 'Start',
        status: 'Follow done / Showed / @.capitalize:lexicon.delivered.singular',
        taskEventType: 'Type',
        title: 'Title',
        user: 'Owner',
        confirmationReply: 'Confirmation response',
        startDate: 'Start date',
        endDate: 'End date',
        deleted: 'deleted a',
        value: 'Task / appointment',
        sendIcs: 'Calendar Reminder',
        userVirtualEventLink: 'Virtual event link advisor',
        customerVirtualEventLink: 'Virtual event link client',
    },
    vehicle: {
        accessories: 'Accessories',
        accidentedDamageCost: 'Accidented Cost',
        actualValue: 'Actual value',
        allowedMileage: 'Allowed KM',
        balance: 'Balance',
        bank: 'Institution',
        baseWarranty: 'Base Warranty',
        bodyType: 'Body type',
        budgetMax: 'Max. budget',
        budgetMin: 'Min. budget ',
        calculatedEquity: 'Equity',
        calculator_value: 'Estimated',
        calculatorAppraiser: 'Apraiser',
        calculatorValue: 'Estimated',
        category: 'Category',
        categoryRv: 'Category RV',
        certified: 'Certified',
        colorExterior: 'Color',
        colorInterior: 'Color Int.',
        comment: 'Comments',
        condition: 'Condition',
        createdAt: 'Date of creation',
        createdBy: 'Created by',
        deletedAt: 'Deleted at',
        documentation: 'Documentation',
        drivingWheels: 'Drivetrain',
        endContractDate: 'Contract ends',
        endWarrantyDate: 'End warranty',
        endWarrantyMileage: 'End warranty KM',
        endExtendedWarrantyDate: 'End extended warranty',
        endExtendedWarrantyMileage: 'End extended warranty KM',
        engine: 'Engine',
        extendedWarranty: 'Extended warranty',
        frequency: 'Frequency',
        fuel: 'Fuel',
        importedBank: 'Institution',
        initialCash: 'Cashdown',
        intention: 'Intention',
        inServiceDate: 'In service date',
        length: 'Length',
        lengthMax: 'Length max.',
        lengthMin: 'Length min.',
        licensePlate: 'License plate',
        link: 'Lien',
        make: 'Make',
        makeWarranty: 'Warranty Type',
        mechanical: 'Mec. product',
        mileage: 'Odometer',
        mileageExcess: 'Estimated mileage excess',
        modality: 'Modality',
        model: 'Model',
        offer: 'Offer #',
        option: 'Options',
        orderNumber: 'Order #',
        payment: 'Payment',
        paymentWithTax: 'Payment with tax',
        preparation: 'Preparation',
        price: 'Price',
        primaryVehicle: 'Primary vehicle',
        profit: 'Profit',
        purchaseDate: 'Purchase date',
        rate: 'Rate',
        recall: 'Recall',
        recordedDate: 'Reported on',
        related: 'DMS',
        requested: 'Requested',
        residual: 'Residual',
        securityDeposit: 'Security deposit',
        service: 'Service',
        sleeping: 'Sleeping',
        sold: '@.capitalize:lexicon.sold.singular vehicle',
        soldBy: '@.capitalize:lexicon.sold.singular by',
        soldDate: '@.capitalize:lexicon.sold.singular date',
        stock: 'Stock #',
        stockState: 'Inventory',
        suffix: 'Suffix',
        term: 'Term',
        tire: 'Tires @:lexicon.sold.singular',
        tireType: 'Tire type',
        tradeNotes: 'Notes',
        tradeType: 'Buying',
        transmission: 'Transmission',
        trim: 'Trim',
        updatedAt: 'Updated',
        updatedBy: 'Updated by',
        url: 'url',
        value: 'Value',
        vehicleState: 'Vehicle State',
        verified: 'Verified',
        verifiedBy: 'Verified',
        vin: 'VIN',
        vinDecoded: 'VIN decoded',
        warranty: 'Warranty',
        weight: 'Weight',
        year: 'Year',
        yearMax: 'Max. year',
        yearMin: 'Min. year',
        drsLinkClient: 'Client Link',
        drsLinkDealer: 'Dealer Link',
    },

    automation: {
        autoCloseLead: 'The lead might get closed',
    },

    attribution: {
        modalTitle: '{leadType} added to the journey by {userName} on {date}',
        noChanges: 'There is no additional information brought into the sales journey',

        added: 'added',
        details: 'Details',
        updated: 'updated',

        communicationCreation: {
            email: '@:history.new',
            phone: '@:history.new',
            sms: '@:history.new',
            messenger: '@:history.new',
            video: '@:history.new',
        },

        emailCreation: '@:history.new {type} email',
        emailType: {
            home: 'personal',
            work: 'professional',
        },

        phoneCreation: '@:history.new {type} phone number',
        phoneType: {
            cell: 'mobile',
            home: 'personal',
            work: 'professional',
        },

        taskEventCreation: {
            call: '@:history.new',
            email: '@:history.new',
            appointment: '@:history.new',
            delivery: '@:history.new',
            other: '@:history.new',
            csi: '@:history.new',
            sms: '@:history.new',
            phone_appointment: '@:history.new',
            virtual_appointment: '@:history.new',
            test_drive: '@:history.new',
        },
    },

    flowUser: {
        order: 'priority {priority}, position {order}',
    },

    flow: {
        notifyPrecedentPriorities: 'Notify previous priorities',
        roundRobin: 'Round Robin',
    },
};
