import { gql } from 'apollo-boost';

export const CORE_USER_FIELDS = gql`
    fragment CoreUserFields on User {
        access_token
        account_id
        active
        allow_email_template_edition
        allow_event_creation
        allow_export_access
        allow_import_access
        analyst_access
        analytics_module_access
        api_user
        appointment_counter
        automation_access
        automation_advanced_access
        availability_status
        bdc_super_access
        bell_event_option
        can_create_child
        block_lead_info
        calculator_value_visible
        campaign
        confidentiality_agreement
        confirmation_timeframe
        created_at
        custom_permissions
        default_dashboard
        do_not_disturb
        email
        end_contract_required
        first_name
        freeze_financial_data
        hide_fi_profits
        hide_in_user_select
        has_analytics_access
        id
        in_turn_edit
        last_mobile_heartbeat_at
        last_name
        locale
        mass_mailing
        module_access
        newer_than
        next_step
        next_step_mandatory
        niotext
        niotext_id
        niotext_token
        no_reassignation_notification
        older_than
        parent_user_id
        post_id
        profile_picture
        reminder_preferences
        role_id
        sensitive_access
        service_super_access
        sex
        supplier_id
        suspended
        team_id
        timezone
        trade_report
        updated_at
        default_account_id,
    }
`;
export const CORE_ACCOUNT_FIELDS = gql`
    fragment CoreAccountFields on Account {
        id
        created_at
        updated_at
        active
        name
        access_all_leads_communications
        account_manager
        activation_date
        activity_report
        address
        alert_task_overlap
        assigned_lead
        auto_assign_associate
        association_flag_lead_duplicated
        auto_recorded_date
        auto_renewal
        auto_renewal_new
        auto_renewal_used
        automation
        bdc_advisor
        billed
        billed_quarterly
        calendar_options
        call_consent_script
        call_summary
        call_summary_required
        campaign
        check_availability_round_robin
        city
        client_card_fields
        client_number
        commercial
        confirmation_appt_sale
        confirmation_appt_service
        country
        credit
        csi
        csi_used
        default_deliveryman_user_id
        delivered_by
        delivery_guest_advisor
        delivery_guest_group_id
        delivery_timeframe
        desking
        disable_communication_audio
        disable_manual_duplicate
        display_approved_sources
        division_required
        duplicates
        email_client
        email_domain
        enforced_mfa
        equity_alert
        event
        facebook_id
        favicon_url
        fiscal_year_date
        full_address
        analytics_workspace_id
        guest_action
        homepage
        in_turn
        in_turn_director_management
        is_imported_in_analytics
        leads_other_division
        leadxpress
        leadxpress_option
        limited_audio_access
        locale
        logo
        logo_en
        loyalty
        mandatory_coordinate
        manually_status
        mark_sold_lead_as_reached
        marketing_domain_active
        merge_rule
        month_start_day
        next_step_stopping
        niotext
        niotext_appointment
        niotext_campaign
        niotext_id
        niotext_phone
        phone
        phone_provider
        phone_up
        postal_code
        power_sport
        province
        recreative_special
        renewal
        renewal_alert_options
        restrict_lead_status_update_for_roles
        restrict_to_activix
        restricted_notifications
        result_date_validation
        round_robin
        pause_round_robin_when_closed
        sale_accessories
        sale_by_phone
        sale_date_month
        sale_table
        sale_table_options
        sale_table_badge_active
        sale_table_badge_field
        sale_validation
        scan
        secondary_advisor
        service
        sid
        source_mandatory
        stock_required_for_sale
        take_over_director
        timezone
        trade_report
        transactional_domain_active
        twilio_sid
        type
        unrestricted_assignment
        unsubscribe
        untreated_lead
        vehicle_image
        vehicle_model_text
        vehicle_text
        verified_sale
        allow_communications_when_verified_sale
        video_conference
        vin_decoder
        vin_manual_decoder
        waiting_sale_date
        waiting_sale_option
        walk_in
        web_order
        webboost
    }
`;

export const CORE_SOURCE_FIELDS = gql`
    fragment CoreSourceFields on Source {
        id
        account_id
        lead_type_id
        provider_id
        source_id
        approved_source_id
        display_on_listing
        name
        active
        provider {
            id
            name
            display_name_fr
            display_name_en
        }
        approved_source {
            id
            name
            provider_id
        }
    }
`;

export const CORE_ACCOUNT_SUPPLIER_FIELDS = gql`
    fragment CoreAccountSupplierFields on AccountSupplier {
        account_id
        account_supplier_id
        company
        contact_email
        contact_phone
        created_at
        display_name
        id
        inbound
        integration_name
        export_vendor_name
        logo
        name
        outbound
        related_id
        updated_at
        url
        user_fullname
        user_id
        user_setting
        active
        type
        manual_send
        lenders
        suspended_temporarily
        suspended
    }
`;

export const CORE_ACCOUNT_PRODUCT_FIELDS = gql`
    fragment CoreAccountProductFields on AccountProduct {
        id
        account_id
        category
        created_at
        default_minutes
        default_price
        name
        order
        type
        updated_at
        visible
    }
`;

export const CORE_DIVISION_FIELDS = gql`
    fragment CoreDivisionFields on Division {
        id
        name
    }
`;

export const CORE_USER_PHONE_FIELDS = gql`
    fragment CoreUserPhoneFields on UserPhone {
        id
        created_at
        updated_at
        user_id
        number
        extension
        is_mobile
        is_preferred
    }
`;

export const CORE_CUSTOM_FIELD_FIELDS = gql`
    fragment CoreCustomFieldFields on CustomField {
        account_id
        created_at
        default
        deleted_at
        display
        field
        id
        name
        order
        section
        select_picker_options
        type
        updated_at
        visible
    }
`;

export const CORE_GUEST_GROUP_FIELDS = gql`
    fragment CoreGuestGroupFields on AccountGuestGroup {
        id
        created_at
        updated_at
        account_id
        name
        roles
        users
        posts
    }
`;

export const CORE_EMAIL_CONFIG_FIELDS = gql`
    fragment CoreEmailConfigFields on EmailConfig {
        id
        created_at
        updated_at
        user_id
        active
        email
        signature_en
        signature_fr
        is_nylas_account_filled
    }
`;

export const CORE_POST_FIELDS = gql`
    fragment CorePostFields on Post {
        id
        locale_specific
        name
        role_id
        sex
    }
`;

export const CORE_RESPONSE_TEMPLATE_FIELDS = gql`
    fragment CoreResponseTemplateFields on ResponseTemplate {
        account_id
        bcc
        bcc_assigned
        cc
        cc_assigned
        created_at
        division_id
        id
        is_advanced
        is_favorite
        is_default
        title
        response_template_custom_category_id
        translations {
            id
            response_template_id
            locale
            title
            subject
            body
            excerpt
            design
            attachments {
                id
                created_at
                updated_at
                response_template_translation_id
                path
                name
                size
            }
        }
        attachments {
            id
            created_at
            updated_at
            response_template_translation_id
            path
            name
            size
        }
        type
        updated_at
    }
`;

export const CORE_TEAM_FIELDS = gql`
    fragment CoreTeamFields on Team {
        account_id
        active
        created_at
        id
        name
        number
        updated_at
    }
`;

export const CORE_CAMPAIGN_FIELDS = gql`
    fragment CoreCampaignFields on Campaign {
        created_at
        end_date
        id
        name
        slug
        start_date
        type
        updated_at
    }
`;

export const CORE_PHONE_PROVIDER_FIELDS = gql`
    fragment CorePhoneProviderFields on PhoneProvider {
        communication_type_id
        created_at
        division_id
        id
        incoming_caller_id
        ivr
        phone
        phone_provider
        phone_provider_id
        press_to_talk
        recording_message
        updated_at
        user_id
    }
`;
